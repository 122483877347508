import React from "react";
import { Link } from "gatsby";

import { Input, Button } from "../components/common";

import Auth from "../components/auth";

function PurchasedPage() {
  return (
    <Auth
      title="Login"
      customNav={
        <div className="body-large">
          Already have an account?{" "}
          <Link className="text-brand-primary" to="/login">
            Login!
          </Link>
        </div>
      }
      headingTitle="Purchased Our Product"
      headingDesc="But don’t have an account?"
      note="Note: You can not create an account without purchasing any of our product!"
    >
      <Input
        name="email"
        label="Enter the same email when you purchased"
        placeholder="email@example.com"
      />
      <Button hasMinWidth={false} text="Send A Confirmation Email" />
    </Auth>
  );
}

export default PurchasedPage;
